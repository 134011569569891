<template>
  <div>
    <h2 v-html="$t(cardTitleLangKey)"></h2>
    <v-text-field id="search_contact_name1" v-model="searchCardStore.Contact_name1" :label="$t('search_contact_name1')" type="text" @keyup.enter="search" />
    <v-text-field id="search_contact_name2" v-model="searchCardStore.Contact_name2" :label="$t('search_contact_name2')" type="text" @keyup.enter="search" />
    <v-text-field id="search_contact_zip" v-model="searchCardStore.Contact_zip" :label="$t('search_contact_zip')" type="text" @keyup.enter="search" />
    <v-text-field
      id="search_contact_locality"
      v-model="searchCardStore.Contact_locality"
      :label="$t('search_contact_locality')"
      type="text"
      @keyup.enter="search"
    />
    <v-text-field id="search_contact_phone" v-model="searchCardStore.Contact_phone" :label="$t('search_contact_phone')" type="text" @keyup.enter="search" />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        id="search_contact_addition"
        v-model="searchCardStore.Contact_addition"
        :label="$t('search_contact_addition')"
        type="text"
        @keyup.enter="search"
      />
      <v-text-field
        id="search_contact_address"
        v-model="searchCardStore.Contact_address1"
        :label="$t('search_contact_address')"
        type="text"
        @keyup.enter="search"
      />
      <v-text-field id="search_contact_email" v-model="searchCardStore.Contact_email" :label="$t('search_contact_email')" type="text" @keyup.enter="search" />
      <v-select
        id="search_contact_languageId"
        v-model="searchCardStore.Contact_languageId"
        :label="$t('search_contact_languageid')"
        :items="languages"
        :item-title="this.$getLangKey()"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <v-select
        id="search_contact_country"
        v-model="searchCardStore.Contact_country"
        :label="$t('search_contact_country')"
        :items="countries"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import baseSearchVue from './baseSearch.vue'
import { defineComponent } from 'vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import { useCountriesStore } from '@/store/enums/CountriesStore'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'

export default defineComponent({
  name: 'searchContact',
  props: {
    cardTitleLangKey: { type: String, required: false, default: 'search_contact_title' }
  },
  extends: baseSearchVue,
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    languages() {
      useLanguagesStore().languages
    },
    countries() {
      return useCountriesStore().Countries
    }
  },

  beforeMount() {
    this.setSearchCardStore(useSearchContactStore(), true)
  },
  mounted() {
    useCountriesStore().fetchCountries()
  }
})
</script>
